import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full'
  },
  
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
  },

  {
    path: 'sesion',
    loadChildren: () => import('./sesion/sesion.module').then( m => m.SesionPageModule)
  },

  {
    path: 'registro',
    loadChildren: () => import('./registro/registro.module').then( m => m.RegistroPageModule)
  },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },

 

  {
    path: 'explora',
    loadChildren: () => import('./explora/explora.module').then( m => m.ExploraPageModule)
  },

  {
    path: 'conoce',
    loadChildren: () => import('./conoce/conoce.module').then( m => m.ConocePageModule)
  },

  {
    path: 'protege',
    loadChildren: () => import('./protege/protege.module').then( m => m.ProtegePageModule)
  },

  {
    path: 'reporta',
    loadChildren: () => import('./reporta/reporta.module').then( m => m.ReportaPageModule)
  },

  {
    path: 'acerca',
    loadChildren: () => import('./acerca/acerca.module').then( m => m.AcercaPageModule)
  },

  {
    path: 'comunidad',
    loadChildren: () => import('./comunidad/comunidad.module').then( m => m.ComunidadPageModule)
  },

  {
    path: 'descubre',
    loadChildren: () => import('./descubre/descubre.module').then( m => m.DescubrePageModule)
  },

  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  
  {
    path: 'agua',
    loadChildren: () => import('./desc/agua/agua.module').then( m => m.AguaPageModule)
  },

  {
    path: 'mapaagua',
    loadChildren: () => import('./desc/mapaagua/mapaagua.module').then( m => m.MapaaguaPageModule)
  },

  {
    path: 'biodiv',
    loadChildren: () => import('./desc/biodiv/biodiv.module').then( m => m.BiodivPageModule)
  },

  {
    path: 'mapabiodiv',
    loadChildren: () => import('./desc/mapabiodiv/mapabiodiv.module').then( m => m.MapabiodivPageModule)
  },
  
  {
    path: 'mapareportes',
    loadChildren: () => import('./mapareportes/mapareportes.module').then( m => m.MapareportesPageModule)
  },
 
  {
    path: 'mapaactores',
    loadChildren: () => import('./mapaactores/mapaactores.module').then( m => m.MapaactoresPageModule)
  },
  
  {
    path: 'especie/:id',
    loadChildren: () => import('./especie/especie.module').then( m => m.EspeciePageModule)
  },

  {
    path: 'desc',
    loadChildren: () => import('./desc/desc.module').then( m => m.DescPageModule)
  },
  
  {
    path: 'techosverdes',
    loadChildren: () => import('./desc/techosverdes/techosverdes.module').then( m => m.TechosverdesPageModule)
  },

  {
    path: 'murosverdes',
    loadChildren: () => import('./desc/murosverdes/murosverdes.module').then( m => m.MurosverdesPageModule)
  },

  {
    path: 'ecosistema/:id',
    loadChildren: () => import('./desc/iniciativasbn/iniciativasbn.module').then( m => m.IniciativasbnPageModule)
  },
  {
    path: 'asistenteia',
    loadChildren: () => import('./asistenteia/asistenteia.module').then( m => m.AsistenteiaPageModule)
  },
  {
    path: 'enlaces',
    loadChildren: () => import('./enlaces/enlaces.module').then( m => m.EnlacesPageModule)
  },
  {
    path: 'mapappal',
    loadChildren: () => import('./mapappal/mapappal.module').then( m => m.MapappalPageModule)
  },
  {
    path: 'registro-ev',
    loadChildren: () => import('./registro-ev/registro-ev.module').then( m => m.RegistroEvPageModule)
  },
  {
    path: 'articulo/:id',
    loadChildren: () => import('./articulo/articulo.module').then( m => m.ArticuloPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
